import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';




const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: 'home',
  //   loadChildren: './home/admin-layout/admin-layout.module#AdminLayoutModule'
  // }
  { path: 'home', loadChildren: () => import(`./home/admin-layout/admin-layout.module`).then(m => m.AdminLayoutModule) }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ModalModule.forRoot(),
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

// @NgModule({
//   imports: [CommonModule,
//     BrowserModule,
//     RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
export class AppRoutingModule { }
